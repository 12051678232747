<template>
	<div>
		<v-row>
			<v-col>
				<s-crud
					title="Estado de Maduración"
					:config="config"
					add
					edit
					remove
					@save="save($event)"
					:filter="filter"
					height="150"
				>
					<template scope="props">
						<v-container>
							<v-row>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<!-- <s-text
										ref="textMtsDescription"
										v-model="props.item.MtsDescription"
										label="Descripción"
									></s-text> -->
									<s-select-definition
										:def="1359"
										label="Estado de maduración"
										v-model="props.item.TypeStateMD"
									>
									</s-select-definition>
								</v-col>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<s-text
										ref="textMtsColor"
										v-model="props.item.MtsColor"
										label="Color"
									></s-text>
								</v-col>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<s-text
										ref="textMtsValueConditional01"
										decimal
										v-model="props.item.MtsValueConditional01"
										label="Valor1"
									></s-text>
								</v-col>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<s-text
										ref="textMtsColorConditional01"
										v-model="props.item.MtsColorConditional01"
										label="Color1"
									></s-text>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<s-text
										ref="textMtsValueConditional02"
										decimal
										v-model="props.item.MtsValueConditional02"
										label="Valor2"
									></s-text>
								</v-col>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<s-text
										ref="textMtsColorConditional02"
										v-model="props.item.MtsColorConditional02"
										label="Color2"
									></s-text>
								</v-col>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<s-text
										ref="textMtsValueConditional03"
										decimal
										v-model="props.item.MtsValueConditional03"
										label="Valor3"
									></s-text>
								</v-col>
								<v-col cols="12" lg="3" md="12" sm="12" class="s-col-form">
									<s-text
										ref="textMtsColorConditional03"
										v-model="props.item.MtsColorConditional03"
										label="Color3"
									></s-text>
								</v-col>
							</v-row>
						</v-container>
					</template>
				</s-crud>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<maturation-state-param></maturation-state-param>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import _sMaturationStateService from "@/services/FrozenProduction/MaturationStateService.js";
	import MaturationStateParam from './MaturationStateParam.vue';
	import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';

	export default {
		components: { 
			MaturationStateParam 
		},
		data() {
			return {
				
				filter: {},
				config: {
					service: _sMaturationStateService,
					model: {
						MtsID: "ID",
						FtsStatus: "number"
					},
					headers: [
						{ text: "ID", value: "MtsID" },
						{ text: "Descripción", value: "MtsDescription" },
						{ text: "Valor1", value: "MtsValueConditional01"},
						{ text: "Color1", value: "MtsColorConditional01"},
						{ text: "Valor2", value: "MtsValueConditional02"},
						{ text: "Color2", value: "MtsColorConditional02"},
						{ text: "Valor3", value: "MtsValueConditional03"},
						{ text: "Color3", value: "MtsColorConditional03"},
					],
				},
			}
		},

		methods: {
			save(item) {
				if(item.DedValue == ""){
							this.$refs.textMtsDescription.error("Ingrese estado de maduración");
							return;
						}
						if(item.MtsColor == ""){
							this.$refs.textMtsColor.error("Ingrese color");
							return;
						}
						if(item.MtsValueConditional01 == 0){
							this.$refs.textMtsValueConditional01.error("Ingrese valor1");
							return;
						}
						if(item.MtsColorConditional01 == ""){
							this.$refs.textMtsColorConditional01.error("Ingrese color 1");
							return;
						}
						if(item.MtsValueConditional02 == 0){
							this.$refs.textMtsValueConditional02.error("Ingrese valor2");
							return;
						}
						if(item.MtsColorConditional02 == ""){
							this.$refs.textMtsColorConditional02.error("Ingrese color2");
							return;
						}
						if(item.MtsValueConditional03 == 0){
							this.$refs.textMtsValueConditional03.error("Ingrese valor3");
							return;
						}
						if(item.MtsColorConditional03 == ""){
							this.$refs.textMtsColorConditional03.error("Ingrese color3");
							return;
						}

						item.save();
				
			}
		},
	}
</script>